<template>
  <b-container class="h-100">
    <div
      class="d-flex flex-column justify-content-center align-items-center position-relative h-100"
    >
      <div
        class="error-container d-flex flex-column justify-content-center h-100"
      >
        <b-card>
          <div class="d-flex justify-content-center">
            <span class="text-xl">Error: page not found</span>
          </div>
        </b-card>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {};
</script>
